import Link, { LinkVariant } from '../../components/Link'

const EnglishContent: React.FC = () => (
  <>

    <h2>Moving Contract</h2>
    <ul>
      <li>Your actual contract will be presented by the movers on the day of your move. The contract must be signed at the beginning and the end of your move. <strong>Please don't hesitate to request the contract if your movers forget to present it to you.</strong></li>
      <li>The final price of your move will be based on the actual time required to perform the move.</li>
    </ul>

    <h2>Payment</h2>

    <ul>
      <li>Payment will be debited from the credit card used for the deposit by MovingWaldo once the move is completed (or on the next business day). You will receive a final invoice from us.</li>
      <li>This allows us to secure the transaction. If you are unsure about your invoice, please contact us and we will mediate.</li>
      <li>Changes to your inventory could impact final cost. To plan accordingly, please make sure to inform your booking agent of any changes.</li>
    </ul>

    <h2>Insurance and liability</h2>

    <ul>
      <li>The moving company which will perform the move will be insured with a duly registered insurance company for public liability and property damage.
        <ul>
          <li>For your information, <strong>basic protection in the moving industry is 60 cents per pound per item</strong>. Most of our partners offer additional coverage. There are associated fees, and the purchase of this protection must be made before the move. Ask your booking agent for more details.</li>
        </ul>
      </li>

      <li>In case of damages during the move, the moving company will be held liable.
        <ul>
          <li>This does not cover damages that occurred before the move or damages resulting from undisclosed conditions existing before the start of the move.</li>
        </ul>
      </li>
    </ul>

    <strong>Claim Process Assistance:</strong> While the claim process must be conducted directly with the moving company, MovingWaldo is dedicated to supporting you every step of the way. To do so, we have a page dedicated to our <Link to="https://www.movingwaldo.com/claim-process/" target="_blank" variant={LinkVariant.Primary}>claim process</Link>, we strongly encourage reviewing this page <strong>prior</strong> to the move. Please note the following:

    <ul>
      <li>Claim process must be started as soon as possible. Please fill out this <Link to="https://www.movingwaldo.com/claim-process/" target="_blank" variant={LinkVariant.Primary}>online form</Link> or reach out to us <strong>within 24 hours of the move</strong>.</li>
      <li><strong>Your move must be paid in full in order to process a claim.</strong></li>
    </ul>

    <h2>Please consider the following</h2>

    <ul>
      <li>Notify us and/or your mover of any changes to your moving plan.</li>
      <li>Be as ready and packed as possible to ensure a smooth move. Here's <Link to="https://www.movingwaldo.com/moving-tips/the-ultimate-moving-checklist-for-canadians-best-version/" target="_blank" variant={LinkVariant.Primary}>MovingWaldo's moving checklist</Link>.</li>
      <li>Reserve a parking spot for the movers near the entry.</li>
      <li>Reiterate parking or elevator instructions to your mover.</li>
      <li>Move jewelry and small valuable items yourself to limit liability.</li>
      <li>Movers may refuse to move certain items if they deem them unsafe, or may require a liability waiver for items that cannot be properly protected.</li>
    </ul>

    <br />
    For any questions, please do not hesitate to contact your booking agent.
    <br />
    Have a smooth move!
  </>
)

export default EnglishContent
