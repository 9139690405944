import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserIcon from '@mui/icons-material/PersonOutlineOutlined'
import MarkerIcon from '@mui/icons-material/RoomOutlined'
import BoxIcon from '@mui/icons-material/Inventory2Outlined'

import {
  type AcceptQuotePayload,
  useAcceptQuoteAction,
  useApplyPromoCodeAction,
  useMove,
  usePrepareMoveDepositAction,
  useSelectedQuote,
} from '../../../modules/moves'
import { usePayment } from '../../../modules/payment/core/payment.hooks'
import { useEffectOnce } from '../../../utils/useEffectOnce'
import SplittedPage from '../../../components/layout/SplittedPage/SplittedPage'
import EditableSections from '../../../components/EditableSections/EditableSections'
import { AddressesSection, IdentificationSection, MoveContentSection } from '../../../components/move/sections'
import CheckoutForm from '../../../components/forms/CheckoutForm'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../components/ToastNotifications'
import ContainedPage from '../../../components/layout/ContainedPage'
import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import SelectedQuote from './SelectedQuote'
import MobileStep from './MobileStep'
import PendingDepositPage from '../PendingDepositPage'
import ValidateAddressesModal from './ValidateAddressesModal'

const CheckoutPage: React.FC = () => {
  const { t } = useTranslation()
  const { acceptQuote } = useAcceptQuoteAction()
  const { applyPromoCode } = useApplyPromoCodeAction()
  const formatError = useErrorFormatter()
  const { data: move } = useMove()
  const { prepareMoveDeposit, loading, error, called } = usePrepareMoveDepositAction()

  const hasDeposit = !!move.deposit
  const checkoutFormReady = hasDeposit || (called && !loading && !error)
  const [hasValidPaymentField, setHasValidPaymentField] = useState(true)
  const [paymentError, setPaymentError] = useState<Error>()
  const getPaymentGateway = usePayment({
    onValidationUpdate: setHasValidPaymentField,
    isDeposit: true,
  })
  const paymentGateway = checkoutFormReady && move.paymentProfile?.depositRequest
    ? getPaymentGateway(move.paymentProfile.depositRequest.gateway)
    : undefined

  const selectedQuote = useSelectedQuote()
  const hasCustomQuote = !!selectedQuote?.customized

  useEffectOnce(() => {
    prepareMoveDeposit().catch(console.error)
  })

  /**
   * handle accept quote
   * handle deposit if required
   */
  const onSubmit = async (value: AcceptQuotePayload) => {
    if (paymentGateway?.preSubmit) {
      if (!await paymentGateway.preSubmit()) {
        return
      }
    }

    try {
      await acceptQuote(value, { ignoreResults: !!paymentGateway })

      if (paymentGateway) {
        try {
          await paymentGateway.submit()
        } catch (error: any) {
          console.error(error)
          setPaymentError(error)
        }
      }
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  /**
   * handle apply promo code
   */
  const onPromoCodeSubmit = async (promoCode: string | null) => {
    try {
      await applyPromoCode(promoCode)
    } catch (error) {
      errorNotification(t('forms.promoCode.invalidOrExpired'))
    }
  }

  if (paymentError) {
    return <PendingDepositPage paymentError={paymentError} />
  }

  if (error) {
    return (
      <ContainedPage>
        <div className="my-8">
          <ServerError error={error} />
        </div>
      </ContainedPage>
    )
  }

  if (!checkoutFormReady) {
    return (
      <ContainedPage>
        <Spinner floating />
      </ContainedPage>
    )
  }

  return (
    <>
      <ValidateAddressesModal />
      <SplittedPage>
        <div className="w-full">
          <div className="mx-2 mb-8 mt-2 flex flex-col gap-8 xl:m-0 xl:hidden">
            <SelectedQuote />
            <MobileStep step={1}>
              { t('pages.checkout.mobileSections.reviewInformation') }
            </MobileStep>
          </div>

          <EditableSections
            sections={[
              {
                key: 'user',
                title: t('components.move.identification.title'),
                icon: UserIcon,
                component: IdentificationSection,
              },
              {
                key: 'addresses',
                title: t('components.move.addresses.title'),
                icon: MarkerIcon,
                component: AddressesSection,
                disabled: hasCustomQuote,
              },
              {
                key: 'moveContent',
                title: t('components.move.moveContent.title'),
                icon: BoxIcon,
                component: MoveContentSection,
                disabled: hasCustomQuote,
              },
            ]}
          />
        </div>

        <div className="w-full">
          <div className="mx-2 mt-2 xl:m-0">
            <MobileStep step={2}>
              { t('pages.checkout.mobileSections.reviewMove') }
            </MobileStep>
          </div>

          <CheckoutForm
            onSubmit={onSubmit}
            onPromoCodeSubmit={onPromoCodeSubmit}
            paymentField={move.paymentProfile?.depositRequest
              ? paymentGateway?.renderPaymentField({
                paymentRequest: move.paymentProfile.depositRequest,
                billingAddress: move.origin.address,
              })
              : undefined}
            paymentRequest={move.paymentProfile?.depositRequest}
            hasValidPaymentField={hasValidPaymentField}
          />
        </div>
      </SplittedPage>
    </>
  )
}

export default CheckoutPage
