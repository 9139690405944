import { Trans, useTranslation } from 'react-i18next'
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined'

import { type PaymentRequest } from '../../../modules/moves'
import { type Amount } from '../../../common/amount'
import Price from '../../Price'
import InfoTooltip from '../../InfoTooltip'

type RequestAmountProps = {
  amount: Amount
}

const RequestAmount: React.FC<RequestAmountProps> = ({ amount }) => {
  return (
    <strong>
      <Price
        amount={amount}
        showDecimals={false}
        showCurrency
        compact
      />
    </strong>
  )
}

type MoveDepositHeaderProps = {
  paymentRequest: PaymentRequest
}

const MoveDepositHeader: React.FC<MoveDepositHeaderProps> = ({
  paymentRequest,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="font-sans text-lg leading-tight text-neutral-700">
        <CreditCardIcon className="!text-[18px] text-neutral-500" />
        { ' ' }

        <Trans
          i18nKey="forms.paymentForm.moveDeposit.title"
        >
          <RequestAmount amount={paymentRequest.amount} />
        </Trans>
        { ' ' }
        <InfoTooltip
          tooltip={t('forms.paymentForm.moveDeposit.tooltip')}
        />
      </div>
      <div className="mb-4 ml-[27px] font-sans text-sm text-neutral-500 ">
        { t('forms.paymentForm.moveDeposit.subtitle') }
      </div>

    </>
  )
}

export default MoveDepositHeader
