import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { isPast } from 'date-fns/isPast'
import { differenceInSeconds } from 'date-fns/differenceInSeconds'

import { useMove, useMoveId, useRemoveSavedMoveIdAction, useSaveMoveAction, useSelectedQuote } from '../../modules/moves'
import { validateUuid } from '../../utils/uuid'
import Spinner from '../../components/Spinner'
import { Error404Page } from '../errors'

import { useConfig } from '../../modules/config'
import { scrollUp } from '../../utils/scroll'
import MoveLockedPage from './MoveLockedPage'
import MoveCalendlyBookedPage from './MoveCalendlyBookedPage'
import AgentRequiredPage from './AgentRequiredPage'
import RefreshingQuotesPage from './RefreshingQuotesPage'
import { useGetPlatform, usePlatform } from '../../modules/whiteLabel'
import { useLocation } from '../../modules/navigation'

type MoveGatePageProps = {
  postMove?: boolean
}

/**
 * The move gate load the move and load the children routes (outlet) only if move is valid
 * this also dispatch to the proper page when move is not active (ex: an agent is required)
 */
const MoveGatePage: React.FC<MoveGatePageProps> = ({
  postMove = false,
}) => {
  const location = useLocation()
  const appUrl = useConfig('app.url')
  const currentPlatform = usePlatform()
  const getPlatform = useGetPlatform()
  const moveId = useMoveId()
  const validId = validateUuid(moveId)
  const { loading, error, data: move } = useMove()
  const selectedQuote = useSelectedQuote()
  const saveMove = useSaveMoveAction()
  const removeSavedId = useRemoveSavedMoveIdAction()
  const quoteMaxAge = useConfig<number>('instantBooking.quote.quoteMaxAge')

  const movePlatform = getPlatform(move?.platform)
  const platformMissMatch = move && movePlatform?.name !== currentPlatform?.name
  const moveLocked = !!move?.locked
  const moveWithNoQuotes = move && (move.quotes?.length ?? 0) === 0
  const requiresAgent = moveWithNoQuotes
  const isMovePast = move && isPast(move.movingDate)
  const hasAcceptedQuote = move && !!move.quoteAccepted
  const calendlyBooked = move && !!move.calendlyScheduledEventUri
  const quotesAge = move?.quotes?.[0]?.createdAt
    ? differenceInSeconds(
      new Date(),
      new Date(move.quotes[0].createdAt),
    )
    : null

  const hasCustomQuote = !!selectedQuote?.customized
  const expiredQuotes = (quotesAge === null || hasAcceptedQuote) ? false : quotesAge >= quoteMaxAge

  /**
   * Since we're not using the router's scroll restoration
   * We ensure we scroll back up when move state change and new screen are rendered
   */
  useEffect(() => {
    scrollUp()
  })

  if (!moveId || !validId) {
    removeSavedId()
    return <Error404Page />
  }

  if (platformMissMatch) {
    const redirect = movePlatform?.url ?? appUrl
    document.location = `${redirect}${location.pathname}${location.search}`
    return <Spinner floating />
  }

  if (!postMove && !hasAcceptedQuote && isMovePast) {
    return <Error404Page />
  }

  if (error) {
    removeSavedId()
    return <Error404Page />
  }

  if (moveLocked) {
    return <MoveLockedPage />
  }

  if (!postMove && requiresAgent && calendlyBooked) {
    return <MoveCalendlyBookedPage />
  }

  if (!postMove && requiresAgent) {
    return <AgentRequiredPage />
  }

  if (loading) {
    return <Spinner floating />
  }

  if (move && !requiresAgent) {
    saveMove(move)
  }

  if (!postMove && expiredQuotes && !hasCustomQuote) {
    return <RefreshingQuotesPage />
  }

  return (
    <Outlet />
  )
}

export default MoveGatePage
