import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/CheckCircleOutline'

import { PageContent } from '../../../../components/Container'
import FullWidthPage from '../../../../components/layout/FullWidthPage'
import { Heading, Subtitle } from '../../../../components/typography'

const PaymentReceivedPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <FullWidthPage>
      <PageContent>

        <div className="my-8 p-4 text-center">
          <Heading variant="h1" className="flex items-center justify-center gap-x-2 text-green-700">
            <CheckIcon className="!text-[30px]" />
            { ' ' }
            { t('pages.move.paymentSuccess.title') }
          </Heading>
          <Subtitle className="py-4 text-lg text-black lg:text-center">
            { t('pages.move.paymentSuccess.subtitle') }
          </Subtitle>
        </div>
      </PageContent>
    </FullWidthPage>

  )
}

export default PaymentReceivedPage
