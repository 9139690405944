import { gql } from '@apollo/client'

import { moveFields } from './moves.queries'

export const createMove = gql`
mutation CreateMove($payload: CreateMoveDto!) {
  createMove(payload: $payload){
    ${moveFields}
  }
}
`

export const updateMove = gql`
mutation UpdateMove($moveId:String!, $payload: UpdateMoveDto!) {
  updateMove(moveId: $moveId, payload: $payload){
    ${moveFields}
  }
}
`

export const refreshQuotes = gql`
mutation Mutation($moveId: String!) {
  refreshQuotes(moveId: $moveId) {
    ${moveFields}
  }
}
`

export const selectQuote = gql`
mutation SelectQuote($moveId: String!, $branchId: String) {
  selectQuote(moveId: $moveId, branchId: $branchId) {
    ${moveFields}
  }
}
`

export const prepareMoveDeposit = gql`
mutation PrepareMoveDeposit($moveId: String!) {
  prepareMoveDeposit(moveId: $moveId) {
    ${moveFields}
  }
}
`

export const prepareMoveBalancePayment = gql`
mutation PrepareMoveBalancePayment($moveId: String!) {
  prepareMoveBalancePayment(moveId: $moveId) {
    ${moveFields}
  }
}
`

export const applyPromoCode = gql`
mutation ApplyPromoCode($moveId: String!, $promoCode: String) {
  applyPromoCode(moveId: $moveId, promoCode: $promoCode) {
    ${moveFields}
  }
}
`

export const acceptQuote = gql`
mutation AcceptQuote($moveId: String!, $payload: AcceptQuoteDto!) {
  acceptQuote(moveId: $moveId, payload: $payload) {
    ${moveFields}
  }
}
`
