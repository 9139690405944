import LabourIcon from '@mui/icons-material/GroupOutlined'
import TripIcon from '@mui/icons-material/MapOutlined'
import ChargesIcon from '@mui/icons-material/ShoppingCartOutlined'
import ProtectionIcon from '@mui/icons-material/HealthAndSafetyOutlined'

import { ChargeCategory } from '../../../modules/invoices'

export type Icon = React.FC<{ className?: string }>

export const categoryIcons: Record<ChargeCategory, Icon> = {
  [ChargeCategory.labour]: LabourIcon,
  [ChargeCategory.tripAndTravel]: TripIcon,
  [ChargeCategory.additionalCharges]: ChargesIcon,
  [ChargeCategory.protectiveMaterial]: ProtectionIcon,
}
