import { useState } from 'react'
import { type useElements, type useStripe } from '@stripe/react-stripe-js'

import {
  type PaymentFieldProps,
  type PaymentGateway,
  type PaymentGatewayOptions,
} from '../gateway.types'
import StripePaymentField from './StripePaymentField'
import StripeWrapper from './StripeWrapper'
import { useConfig } from '../../../config'
import { usePathGenerator } from '../../../navigation'
import Route from '../../../../app/Route.enum'

export const useStripeGateway = ({
  onValidationUpdate,
  isDeposit = false,
}: PaymentGatewayOptions = {}): PaymentGateway => {
  const [stripe, setStripe] = useState<ReturnType<typeof useStripe>>()
  const [elements, setElements] = useState<ReturnType<typeof useElements>>()
  const [isValid, setIsValid] = useState(false)
  const [hasError, setHasError] = useState(false)
  const appUrl = useConfig('app.url')
  const generatePath = usePathGenerator()
  const returnUrl = `${appUrl}${generatePath(Route.StripeLandingPage)}?deposit=${isDeposit ? '1' : '0'}`

  /**
   * handle form validation update
   */
  const handleValidationUpdate = (valid: boolean) => {
    onValidationUpdate?.(valid)
    setIsValid(valid)
    setHasError(false)
  }

  /**
   * renders payment field using a secure iframe
   */
  const renderPaymentField = (props: PaymentFieldProps) => {
    return (
      <StripeWrapper clientSecret={props.paymentRequest.clientSecret}>
        <StripePaymentField
          {...props}
          setStripe={setStripe}
          setElements={setElements}
          onValidationUpdate={handleValidationUpdate}
          hasError={hasError}
        />
      </StripeWrapper>
    )
  }

  /**
   * this submits the stripe elements but doesn't charge the customer yet
   * this validates the payment data
   */
  const preSubmit = async () => {
    if (!stripe || !elements) {
      throw new Error('Missing stripe context')
    }

    /* when we already know some fields are invalid, just validate other fields */
    if (!isValid) {
      await elements.submit()
      onValidationUpdate?.(false)
      return false
    }

    /* submit Stripe elements */
    let success = false
    try {
      const response = await elements.submit()
      const hasError = !!response.error
      if (hasError) {
        console.error(response.error)
      } else {
        success = true
      }
    } catch (error) {
      console.error(error)
    }

    /* handle response */
    setHasError(!success)
    onValidationUpdate?.(success)
    return success
  }

  /**
   * this complete the payment
   */
  const submit = async () => {
    if (!stripe || !elements) {
      throw new Error('Missing stripe context')
    }

    const payment = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    })

    if (payment.error) {
      throw new Error(payment.error.message)
    }
  }

  return {
    renderPaymentField,
    preSubmit,
    submit,
  }
}
