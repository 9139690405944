import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'

import {
  type Move,
  type PaymentRequest,
} from '../../../modules/moves'
import { usePayment } from '../../../modules/payment/core/payment.hooks'
import { useEffectOnce } from '../../../utils/useEffectOnce'
import ServerError from '../../../components/errors/ServerError'
import Spinner from '../../../components/Spinner'
import PaymentForm from '../../../components/forms/PaymentForm'
import { errorNotification } from '../../../components/ToastNotifications'
import Alert from '../../../components/Alert'
import Button, { ButtonVariant } from '../../../components/Button'

type PaymentPageContentProps = {
  move: Move
  paymentRequest?: PaymentRequest
  paymentError?: Error
  isDeposit?: boolean
  preparePayment: {
    prepare: () => Promise<Move>
    loading: boolean
    error?: Error
    called: boolean
  }
}

const PaymentPageContent: React.FC<PaymentPageContentProps> = ({
  move,
  paymentRequest,
  paymentError,
  isDeposit = false,
  preparePayment,
}) => {
  const { t } = useTranslation()

  const { prepare, loading, error, called } = preparePayment

  const [hasValidPaymentField, setHasValidPaymentField] = useState(true)
  const getPaymentGateway = usePayment({
    onValidationUpdate: setHasValidPaymentField,
    isDeposit,
  })
  const intercom = useIntercom()

  const formatPaymentError = (error: any) => {
    return t(
      'pages.move.paymentPageContent.error',
      { reason: String(error.message ?? 'Unknown') },
    )
  }

  const needPaymentRequestUpdate = !paymentError
  const paymentRequestReady = !needPaymentRequestUpdate || (called && !loading && !error)

  const paymentGateway = paymentRequestReady && paymentRequest
    ? getPaymentGateway(paymentRequest.gateway)
    : undefined

  const paymentFormReady = !error && !!paymentGateway && paymentRequest

  useEffectOnce(() => {
    if (needPaymentRequestUpdate) {
      prepare().catch(console.error)
    }
  })

  const handleSubmit = async () => {
    if (!paymentGateway) {
      return
    }
    if (paymentGateway.preSubmit) {
      if (!await paymentGateway.preSubmit()) {
        return
      }
    }

    try {
      await paymentGateway.submit()
    } catch (error: any) {
      console.error(error)
      errorNotification(formatPaymentError(error))
    }
  }

  const openChat = () => {
    intercom.show()
  }

  return (
    <>

      { paymentError &&
        (
          <div className="my-8">
            <Alert className="text-md justify-center">
              { formatPaymentError(paymentError) }
            </Alert>
          </div>
        ) }

      <div className="flex justify-center">
        <div className="xl:w-[650px]">

          { error && <ServerError error={error} /> }
          { !error && !paymentFormReady && <Spinner floating /> }
          { paymentFormReady && !!paymentRequest && (
            <PaymentForm
              onSubmit={handleSubmit}
              paymentField={paymentGateway.renderPaymentField({
                paymentRequest,
                billingAddress: move.origin.address,
              })}
              paymentRequest={paymentRequest}
              hasValidPaymentField={hasValidPaymentField}
              isDeposit={isDeposit}
            />
          ) }

        </div>
      </div>

      <div className="my-4 text-center">
        <Button
          onClick={openChat}
          variant={ButtonVariant.Transparent}
          className="!text-base !text-neutral-600 !underline"
        >
          { t('pages.move.paymentPageContent.havingPaymentIssue') }
        </Button>
      </div>
    </>
  )
}

export default PaymentPageContent
