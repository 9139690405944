import { gql } from '@apollo/client'

import { publicCompanyFields } from '../../companies/core/companies.queries'

export const quoteFields = `
id
createdAt
customized
date
forAlternativeDate
companyBranch {
  ${publicCompanyFields}
}
straightLineDistance
resolvedNbMen
resolvedNbTrucks {
  key
  value
}
details {
  pricingCategory
  description
  overrideDescription
  item
  quantity
  unit
  unitPrice {
    price
    currency
  }
  subtotal {
    price
    currency
  }
}
subtotal {
  price
  currency
}
alternativeDates {
  date
  subtotal {
    price
    currency
  }
  dateAvailable
  timeAvailable
  availableAm
  availablePm
}
constraints
best
`

export const preliminaryQuoteFields = `
totalQuotes
preliminaryQuotes {
  id
  companyLogo
  companyLetter
  price {
    price
    currency
  }
  nbMen
  nbTrucks
}
resolvedOriginAddress {
  city
  region
  country
  postalCode
}
resolvedDestinationAddress {
  city
  region
  country
  postalCode
}
`

export const getPreliminaryQuotes = gql`
query GetPreliminaryQuotes($preliminaryQuotesRequest: QuotesRequestDto!) {
  getPreliminaryQuotes(preliminaryQuotesRequest: $preliminaryQuotesRequest) {
    ${preliminaryQuoteFields}
  }
}
`
