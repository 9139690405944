import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'
import TipsIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import ChatIcon from '@mui/icons-material/ChatOutlined'

import { useLanguage } from '../../modules/i18n'
import ContainedPage from '../../components/layout/ContainedPage'
import { PageContent } from '../../components/Container'
import { Heading } from '../../components/typography'
import EnglishContent from './MoveReminder.en'
import FrenchContent from './MoveReminder.fr'

const MoveReminderPage: React.FC = () => {
  const { t } = useTranslation()
  const language = useLanguage()
  const intercom = useIntercom()

  const openChat = () => {
    intercom.show()
  }

  return (
    <ContainedPage>
      <PageContent>
        <div className="flex justify-between gap-x-8">

          <div>
            <Heading variant="h1" className="pb-10 pt-2">
              <TipsIcon className="-mt-3 mr-4 !text-[32px] text-blue-800" />
              { t('pages.moveReminder.title') }
            </Heading>

            <div className="prose mb-8 max-w-none font-body prose-headings:font-body2">
              { language === 'fr' ? <FrenchContent /> : <EnglishContent /> }
            </div>
          </div>

          <div className="sticky top-[100px] hidden h-full lg:block">
            <div className="relative w-[300px] overflow-hidden rounded-xl">
              <img
                src="/images/moving-photos/hero2.png"
                alt=""
                className="aspect-[2/5] w-full bg-gray-900/5 object-cover shadow-lg"
              />
              <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>

            <button
              className="my-6 flex w-full items-center justify-center gap-x-3 rounded-xl border border-primary p-4 font-sans text-lg text-primary transition-all duration-300 hover:bg-primary/75 hover:text-white hover:shadow-lg"
              onClick={openChat}
            >
              <ChatIcon className="!text-[18px]" />
              { t('pages.moveReminder.needAssistance') }
            </button>

          </div>
        </div>
      </PageContent>
    </ContainedPage>
  )
}

export default MoveReminderPage
