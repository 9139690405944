import { useTranslation } from 'react-i18next'

import {
  useMove,
  usePrepareMoveDepositAction,
} from '../../../modules/moves'
import { PageContent } from '../../../components/Container'
import ContainedPage from '../../../components/layout/ContainedPage'
import { Heading, Subtitle } from '../../../components/typography'
import PaymentPageContent from '../PaymentPageContent'

type PendingDepositPageProps = {
  paymentError?: Error
}

const PendingDepositPage: React.FC<PendingDepositPageProps> = ({ paymentError }) => {
  const { t } = useTranslation()
  const { data: move } = useMove()
  const {
    prepareMoveDeposit,
    ...preparePayment
  } = usePrepareMoveDepositAction()

  return (
    <ContainedPage>
      <PageContent>
        <div className="pt-4 text-center">

          <Heading variant="h1">
            { t('pages.move.pendingDeposit.title') }
          </Heading>

          <Subtitle className="mb-6 lg:text-center">
            { t('pages.move.pendingDeposit.subtitle') }
          </Subtitle>

          <PaymentPageContent
            move={move}
            paymentRequest={move.paymentProfile?.depositRequest}
            paymentError={paymentError}
            preparePayment={{
              prepare: prepareMoveDeposit,
              ...preparePayment,
            }}
            isDeposit
          />

        </div>
      </PageContent>
    </ContainedPage>
  )
}

export default PendingDepositPage
