import { useEffect } from 'react'

import { useConfig } from '../modules/config'
import { useChangeLanguageAction } from '../modules/i18n'
import { useNavigate } from '../modules/navigation'
import { Error404Page } from '../pages/errors'

const LanguageSwitcher: React.FC = () => {
  const languages = Object.keys(useConfig('i18n.locales'))
  const changeLanguage = useChangeLanguageAction()
  const { replace } = useNavigate()

  const pathname = document?.location?.pathname
  const queryString = document?.location?.search ?? ''
  const hash = document?.location?.hash ?? ''
  const lang = pathname?.substring(1, 3)?.toLowerCase()
  const page = `${pathname?.substring(3) || '/'}${queryString}${hash}`

  /**
   * set language and replace with requested page
   */
  useEffect(() => {
    if (lang) {
      changeLanguage(lang)
      replace(page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  if (!languages.includes(lang)) {
    return <Error404Page />
  }

  return null
}

export default LanguageSwitcher
