import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useFormState } from 'react-hook-form'

import { type PaymentRequest } from '../../../modules/moves'
import Form, {
  SubmitButton,
} from '../../Form'
import MoveDepositHeader from './MoveDepositHeader'

type PaymentFormProps = {
  onSubmit: () => Promise<void> | void
  paymentField: React.ReactNode
  paymentRequest: PaymentRequest
  hasValidPaymentField: boolean
  isDeposit?: boolean
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  onSubmit,
  paymentField,
  paymentRequest,
  hasValidPaymentField = true,
  isDeposit = false,
}) => {
  const { t } = useTranslation()
  const form = useForm()
  const paymentFieldName = '_payment'
  const { submitCount } = useFormState({ control: form.control })

  /**
   * set form error when there's a payment field is invalid
   */
  useEffect(() => {
    if (submitCount === 0) {
      return
    }
    if (hasValidPaymentField) {
      form.clearErrors(paymentFieldName)
    } else {
      form.setError(paymentFieldName, {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasValidPaymentField, submitCount])

  return (
    <Form form={form} onSubmit={onSubmit}>

      <div className="my-4 flex flex-col justify-between gap-2 rounded-md border bg-white p-4 text-neutral-500 lg:p-8">

        { isDeposit && <MoveDepositHeader paymentRequest={paymentRequest} /> }
        { paymentField }

      </div>

      <div className="mb-6 mt-8 flex w-full justify-center sm:w-auto xl:mb-0 xl:mt-6">
        <SubmitButton className="w-full sm:w-auto">
          { t('actions.continue') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default PaymentForm
