import { useMemo } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import {
  type Quote,
  QuotesView,
  useChangeQuotesViewAction,
  useSortedQuote,
} from '../../quotes'
import { type QueryResult } from '../../graphQl'
import { useLocation } from '../../navigation'
import { validateUuid } from '../../../utils/uuid'
import * as queries from './moves.queries'
import * as mutations from './moves.mutations'
import { type Move } from './moves.models'
import { buildMove } from './moves.builder'
import {
  type AcceptQuotePayload,
  type CreateMovePayload,
  type UpdateMovePayload,
} from './moves.types'
import * as selectors from './moves.selectors'
import * as actions from './moves.actions'
import {
  pushMoveCreatedEvent,
  pushAgentBookedEvent,
  pushQuoteSelectedEvent,
  pushQuoteUnselectedEvent,
  pushQuoteAcceptedEvent,
  pushPromoCodeAddedEvent,
  pushPromoCodeRemovedEvent,
} from '../../analytics'
import Language from '../../../app/Language.enum'
import { useIntercom } from 'react-use-intercom'
import { getCheapestQuotePrice } from '../../quotes/core/utils/quotes.utils'

type AcceptQuoteOptions = {
  ignoreResults?: boolean
}

/**
 * returns current move id based on routing
 * this is not using useParams on purpose since we want to read the move id outside of the route
 */
export const useMoveId = () => {
  const location = useLocation()
  return useMemo(() => {
    return location.pathname.match(/\/move\/([^/]+)/i)?.[1]
  }, [location.pathname])
}

/**
 * returned the last move of the user (saved in store)
 */
export const useSavedMoveId = () => {
  return useSelector(selectors.moveId)
}

/**
 * save the move id in store
 */
export const useSaveMoveAction = () => {
  const savedMoveId = useSavedMoveId()
  const isLoggingOut = useSelector(selectors.isLoggingOut)
  const dispatch = useDispatch()
  const changeQuotesView = useChangeQuotesViewAction()

  return (move: Move) => {
    if (isLoggingOut || move.id === savedMoveId) {
      return
    }
    dispatch(actions.setMoveId(move.id))
    changeQuotesView(move.flexibleDate ? QuotesView.cheapest : QuotesView.best)
  }
}

/**
 * remove the currently saved move
 */
export const useRemoveSavedMoveIdAction = () => {
  const dispatch = useDispatch()
  return () => {
    dispatch(actions.setMoveId(undefined))
  }
}

/**
 * remove the currently saved move
 */
export const useLogoutAction = () => {
  const intercom = useIntercom()
  const dispatch = useDispatch()
  const removeSavedId = useRemoveSavedMoveIdAction()
  return () => {
    intercom.hardShutdown()
    dispatch(actions.setIsLoggingOut())
    removeSavedId()
    setTimeout(() => {
      document.location = '/'
    }, 100)
  }
}

/**
 * returns current move
 */
export const useMove = ({ reload = false, ...queryArgs } = {}): QueryResult<Move> => {
  const moveId = useMoveId()
  const validId = validateUuid(moveId)

  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getMove,
    {
      skip: !validId,
      variables: { moveId },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: buildMove(data?.getMove) as Move,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * returns selected quote if any
 */
export const useSelectedQuote = (): Quote | undefined => {
  const { data: move } = useMove()
  const selectedQuote = move?.quotes?.find(({ companyBranch }) => companyBranch.id === move.selectedBranch)
  return selectedQuote
}

/**
 * create a move
 */
export const useCreateMoveAction = () => {
  const [createMove, { data, loading, ...mutationProps }] = useMutation(mutations.createMove)

  return {
    createMove: async (payload: CreateMovePayload) => {
      const { data } = await createMove({
        variables: {
          payload,
        },
      })

      /* tracking */

      const move = buildMove(data.createMove) as Move

      /* tracking "online" quotes */

      const nbQuotesReceived = move.quotes?.length ?? 0
      const cheapestPriceReceived = getCheapestQuotePrice(move)
      const fromCity = 'city' in move.origin.address ? move.origin.address?.city : undefined
      const fromRegion = 'region' in move.origin.address ? move.origin.address?.region : undefined
      const toCity = 'city' in move.destination.address ? move.destination.address?.city : undefined
      const toRegion = 'region' in move.destination.address ? move.destination.address?.region : undefined

      pushMoveCreatedEvent({
        nbQuotesReceived,
        cheapestPriceReceived,
        fromCity,
        fromRegion,
        toCity,
        toRegion,
      })

      return move
    },
    data: buildMove(data?.createMove) as Move,
    loading,
    ...mutationProps,
  }
}

/**
 * update a move
 */
export const useUpdateMoveAction = () => {
  const { data: move } = useMove()
  const [updateMove, { data, loading, ...mutationProps }] = useMutation(mutations.updateMove)

  return {
    updateMove: async (payload: UpdateMovePayload) => {
      const { data } = await updateMove({
        variables: {
          moveId: move.id,
          payload,
        },
      })

      /* tracking */
      if (payload.calendlyScheduledEventUri) {
        pushAgentBookedEvent()
      }

      return buildMove(data.updateMove) as Move
    },
    data: buildMove(data?.updateMove) as Move,
    loading,
    ...mutationProps,
  }
}

/**
 * refresh quotes
 */
export const useRefreshQuotesAction = () => {
  const { data: move } = useMove()
  const [refreshQuotes, { data, loading, ...mutationProps }] = useMutation(mutations.refreshQuotes)

  return {
    refreshQuotes: async () => {
      const { data } = await refreshQuotes({
        variables: {
          moveId: move.id,
        },
      })

      return buildMove(data.refreshQuotes) as Move
    },
    data: buildMove(data?.refreshQuotes) as Move,
    loading,
    ...mutationProps,
  }
}

/**
 * select/unselect a quote
 */
export const useSelectQuoteAction = () => {
  const [selectQuote, { data, loading, ...mutationProps }] = useMutation(mutations.selectQuote)
  const { data: move } = useMove()
  const quotes = useSortedQuote(move.quotes ?? [])

  return {
    selectQuote: async (quote: Quote | null) => {
      const { data } = await selectQuote({
        variables: {
          moveId: move.id,
          branchId: quote?.companyBranch.id ?? null,
        },
      })

      /* tracking */
      if (quote) {
        const position = (quotes?.findIndex(({ id }) => id === quote.id) ?? 0) + 1
        pushQuoteSelectedEvent({
          companyId: quote.companyBranch.id,
          companyName: quote.companyBranch.identification?.name[Language.En] ??
          quote.companyBranch.company.identification.name[Language.En],
          price: quote.subtotal,
          companyCity: quote.companyBranch.tripAndTravel?.trucksOriginAddress.city,
          position,
        })
      } else {
        pushQuoteUnselectedEvent()
      }

      return buildMove(data.selectQuote) as Move
    },
    data: buildMove(data?.selectQuote) as Move,
    loading,
    ...mutationProps,
  }
}

/**
 * prepare move deposit
 */
export const usePrepareMoveDepositAction = () => {
  const { data: move } = useMove()
  const [prepareMoveDeposit, { data, loading, ...mutationProps }] = useMutation(mutations.prepareMoveDeposit)

  return {
    prepareMoveDeposit: async () => {
      const { data } = await prepareMoveDeposit({
        variables: {
          moveId: move.id,
        },
      })

      return buildMove(data.prepareMoveDeposit) as Move
    },
    data: buildMove(data?.prepareMoveDeposit) as Move,
    loading,
    ...mutationProps,
  }
}

/**
 * prepare move deposit
 */
export const usePrepareMoveBalancePaymentAction = () => {
  const { data: move } = useMove()
  const [prepareMoveBalancePayment, { data, loading, ...mutationProps }] = useMutation(mutations.prepareMoveBalancePayment)

  return {
    prepareMoveBalancePayment: async () => {
      const { data } = await prepareMoveBalancePayment({
        variables: {
          moveId: move.id,
        },
      })

      return buildMove(data.prepareMoveBalancePayment) as Move
    },
    data: buildMove(data?.prepareMoveBalancePayment) as Move,
    loading,
    ...mutationProps,
  }
}

/**
 * select/unselect a quote
 */
export const useApplyPromoCodeAction = () => {
  const [applyPromoCode, { data, loading, ...mutationProps }] = useMutation(mutations.applyPromoCode)
  const { data: move } = useMove()

  return {
    applyPromoCode: async (promoCode: string | null) => {
      const originalPromoCode = move.promoCode?.code
      const { data } = await applyPromoCode({
        variables: {
          moveId: move.id,
          promoCode,
        },
      })
      const newPromoCode = data.applyPromoCode?.promoCode?.code

      /* tracking */

      if (promoCode && newPromoCode) {
        pushPromoCodeAddedEvent(newPromoCode)
      } else if (!promoCode && originalPromoCode) {
        pushPromoCodeRemovedEvent(originalPromoCode)
      }

      return buildMove(data.applyPromoCode) as Move
    },
    data: buildMove(data?.applyPromoCode) as Move,
    loading,
    ...mutationProps,
  }
}

/**
 * accept a quote
 */
export const useAcceptQuoteAction = () => {
  const { data: move } = useMove()
  const [acceptQuote, { data, loading, ...mutationProps }] = useMutation(mutations.acceptQuote)
  const selectedQuote = useSelectedQuote()

  return {
    acceptQuote: async (
      payload: AcceptQuotePayload,
      { ignoreResults }: AcceptQuoteOptions = {},
    ) => {
      if (!selectedQuote) {
        throw new Error('No selected quote')
      }
      const { data } = await acceptQuote({
        variables: {
          moveId: move.id,
          payload,
        },
        ignoreResults,
        fetchPolicy: ignoreResults ? 'no-cache' : undefined,
      })

      /* tracking */
      pushQuoteAcceptedEvent({
        companyId: selectedQuote.companyBranch.id,
        companyName: selectedQuote.companyBranch.identification?.name[Language.En] ??
        selectedQuote.companyBranch.company.identification.name[Language.En],
        price: selectedQuote.subtotal,
        companyCity: selectedQuote.companyBranch.tripAndTravel?.trucksOriginAddress.city,
        promoCode: move.promoCode?.code,
      })

      return buildMove(data.acceptQuote) as Move
    },
    data: buildMove(data?.acceptQuote) as Move,
    loading,
    ...mutationProps,
  }
}
