import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import PhoneIcon from '@mui/icons-material/LocalPhoneOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import MoveAgainIcon from '@mui/icons-material/Replay'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import TacIcon from '@mui/icons-material/Gavel'
import PrivacyPolicyIcon from '@mui/icons-material/VerifiedUserOutlined'

import MobileMenuLink from './MobileMenuLink'
import InstallPwaMobileLink from './InstallPwaMobileLink'
import { LanguageSelector, useLanguage } from '../../../modules/i18n'
import Route from '../../../app/Route.enum'
import { useLogoutAction, useSavedMoveId } from '../../../modules/moves'
import Language from '../../../app/Language.enum'
import Hamburger from 'hamburger-react'

type MobileMenuProps = {
  open: boolean
  onClose: () => void
}

const MobileMenu: React.FC<MobileMenuProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const language = useLanguage()
  const moveId = useSavedMoveId()
  const logout = useLogoutAction()

  const hasMoveSaved = !!moveId

  const becomePartnerUrl = language === Language.En
    ? 'https://www.movingwaldo.com/move-campaign/'
    : 'https://www.movingwaldo.com/fr/campagne-demenagement/'

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed top-[68px] z-50"
        onClose={onClose}
      >
        { open && (
          <div className="fixed right-[2px] top-0 z-[999] p-[10px]">
            <Hamburger
              toggled
              direction="right"
              size={25}
              rounded
            />
          </div>
        ) }
        { /* backdrop */ }
        <TransitionChild
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 top-[68px] bg-gray-600/75 backdrop-blur-sm" />
        </TransitionChild>

        <TransitionChild
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <DialogPanel className="fixed right-0 inline-flex h-[calc(100dvh-68px)] w-[360px] max-w-[90vw] overflow-y-auto rounded-l-md border-t bg-white">

            <div
              className="flex h-full grow flex-col justify-between"
              data-testid="mobile-menu"
            >
              <div className="pt-2">
                <div className="flex items-center justify-end border-b pb-3 pr-4">
                  <LanguageSelector onClick={onClose} className="ml-5" />
                  { /* <DarkModeSwitcher /> */ }
                </div>

                <MobileMenuLink
                  to="tel:18442586683"
                  Icon={PhoneIcon}
                  target="_blank"
                >
                  1 (844) 258-MOVE (6683)
                </MobileMenuLink>

                { hasMoveSaved && (
                  <MobileMenuLink
                    to="#"
                    Icon={MoveAgainIcon}
                    onClick={logout}
                  >
                    { t('header.mobileMenu.moveAgain') }
                  </MobileMenuLink>
                ) }

                <MobileMenuLink
                  to={becomePartnerUrl}
                  Icon={TruckIcon}
                  target="_blank"
                >
                  { t('header.mobileMenu.becomeAPartner') }
                </MobileMenuLink>
              </div>

              <div className="pb-2">

                <MobileMenuLink
                  to={Route.TermsOfUse}
                  Icon={TacIcon}
                >
                  { t('layout.termsOfUse') }
                </MobileMenuLink>

                <MobileMenuLink
                  to={Route.PrivacyPolicy}
                  Icon={PrivacyPolicyIcon}
                >
                  { t('layout.privacyPolicy') }
                </MobileMenuLink>

                <InstallPwaMobileLink>{ t('header.mobileMenu.install') }</InstallPwaMobileLink>
                { hasMoveSaved && (
                  <MobileMenuLink
                    to="#"
                    Icon={LogoutIcon}
                    onClick={logout}
                  >
                    { t('header.logout') }
                  </MobileMenuLink>
                ) }
              </div>
            </div>

          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  )
}

export default MobileMenu
