import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import PromoCodeIcon from '@mui/icons-material/LocalOffer'

import { type Amount, Currency } from '../../../common/amount'
import { type Quote } from '../../quotes'
import { type PromoCode } from './promoCodes.models'
import { applyPromoCode } from './promoCodes.utils'
import { PromoCodeType } from './promoCodes.enums'
import Price from '../../../components/Price'

/**
 * apply a promo code to a quotes and returns the rebate
 * returns undefined if the rebate is zero
 */
export const usePromoCodeRebate = (quote?: Quote, promoCode?: PromoCode): Amount | undefined => {
  const updatedSubtotal = useQuoteSubtotalWithPromoCode(quote, promoCode)
  if (!quote || !updatedSubtotal) {
    return
  }
  const rebate = quote.subtotal.price - updatedSubtotal.price

  if (rebate === 0) {
    return
  }

  return {
    ...quote.subtotal,
    price: rebate * -1,
  }
}

/**
 * apply a promo code to a quote and returns the new subtotal
 */
export const useQuoteSubtotalWithPromoCode = (quote?: Quote, promoCode?: PromoCode): Amount | undefined => {
  if (!quote) {
    return
  }
  if (!promoCode) {
    return quote.subtotal
  }
  return applyPromoCode(quote.subtotal, promoCode)
}

/**
 * returns a tag with the label of the promo code
 */
export const usePromoCodeLabel = (promoCode?: PromoCode, currency: Currency = Currency.CAD) => {
  const { t } = useTranslation()
  if (!promoCode) {
    return null
  }
  return (
    <Chip
      color="success"
      icon={<PromoCodeIcon className="!ml-3 !text-[14px]" />}
      label={(
        <div className="mx-1 flex items-center gap-2 font-body2 text-base">
          { promoCode.type === PromoCodeType.Amount && (
            <Price
              amount={{ price: promoCode.value, currency }}
              showDecimals={false}
              compact
            />
          ) }
          { promoCode.type === PromoCodeType.Percentage && (
            <>
              { promoCode.value }%
            </>
          ) }
          { ' ' }
          <span className="text-xs text-gray-300">
            { t('promoCode.off') }
          </span>
        </div>
      )}
    />
  )
}
