import { type Amount } from '../../../common/amount'

export enum ChargeUnit {
  hour = 'hour',
  km = 'km',
}

export enum ChargeCategory {
  labour = 'labour',
  tripAndTravel = 'tripAndTravel',
  additionalCharges = 'additionalCharges',
  protectiveMaterial = 'protectiveMaterial',
}

export enum ChargeDescription {
  movingLabour = 'movingLabour',
  packingLabour = 'packingLabour',
  travelLabour = 'travelLabour',
  transportLabour = 'transportLabour',

  travelTruckFeePerKilometer = 'travelTruckFeePerKilometer',
  transportTruckFeePerKilometer = 'transportTruckFeePerKilometer',
  fixedTruckFee = 'fixedTruckFee',

  additionalChargesOvernight = 'additionalChargesOvernight',
  additionalChargesStairs = 'additionalChargesStairs',
  additionalChargesSpecialServices = 'additionalChargesSpecialServices',
  additionalChargesSpecialItems = 'additionalChargesSpecialItems',

  protectiveMaterialProduct = 'protectiveMaterialProduct',

  customCharge = 'customCharge',
  customChargeMw = 'customChargeMw',
}

export type Charge = {
  pricingCategory: ChargeCategory
  description: ChargeDescription
  overrideDescription?: string
  item?: string
  quantity: number
  unit?: ChargeUnit
  unitPrice: Amount
  subtotal: Amount
  taxable?: boolean
}
