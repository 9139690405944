import { mergeClassName } from '../../utils/mergeClassName'

/* eslint-disable react/no-array-index-key */
type ProgressBarProps = {
  nbDots?: number
  progress: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  nbDots,
  progress,
}) => {
  progress = Math.min(Math.max(progress, 0), 100)

  return (
    <div className="relative flex h-[30px] items-center">

      { nbDots && nbDots > 0 && Array.from({ length: nbDots }).map((_, index) => {
        const dotPercentage = (100 / (nbDots - 1)) * index
        const dotActive = Math.ceil(progress) >= Math.ceil(dotPercentage)

        return (
          <div
            key={index}
            className={mergeClassName(
              'h-[16px] w-[16px] rounded-full bg-neutral-200 absolute z-[2] ml-[-8px]',
              dotActive && 'bg-primary',
            )}
            style={{ left: `${dotPercentage}%` }}
          />
        )
      }) }

      <div className="absolute left-0 h-[3px] w-full bg-neutral-200" />
      <div
        className="absolute left-0 h-[3px] bg-primary transition-width duration-300"
        style={{ width: `${progress}%` }}
      />
    </div>
  )
}

export default ProgressBar
