enum Route {
  HomePage = '/',
  TermsOfUse = '/terms-of-use',
  PrivacyPolicy = '/privacy-policy',
  QuotesRequest = '/quotes-request',
  Move = '/move/:moveId',
  EditMove = '/move/:moveId/edit',
  PayBalance = '/move/:moveId/pb',
  StripeLandingPage = '/move/:moveId/stripe',
  PaymentReceivedPage = '/move/:moveId/payment-success',
  FreeConsultation = '/free-consultation',
  MoveReminder = '/move-reminder',
}

export default Route
